import React, { useEffect, useState } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from 'chart.js';
import { Divider, Stack } from '@mui/material';
import { getAllFormSubmissionSummary } from '../../services/api';
import Typography from '../../components/atoms/Typography';

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale
);

// Types based on the backend DTO structure
interface FormSubmissionStatsDTO {
    totalSubmissions: number;
    submissionsByStatus: { [key in FormSubmissionStatus]: number };
    submissionsByVendor: { [key: string]: VendorInfo };
    submissionsByVendorAndStatus: { [key: string]: VendorStatusInfo };
    overdueSubmissions: number;
    submissionsDueInNextWeek: number;
}

interface VendorInfo {
    vendorId: string;
    vendorName: string;
    submissionCount: number;
}

interface VendorStatusInfo {
    vendorId: string;
    vendorName: string;
    statusCounts: { [key in FormSubmissionStatus]: number };
}

enum FormSubmissionStatus {
    IN_PROGRESS = 'IN_PROGRESS',
    SUBMITTED = 'SUBMITTED',
    OVERDUE = 'OVERDUE',
    REOPENED = 'REOPENED',
    COMPLETED = 'COMPLETED',
    NOT_APPLICABLE = 'NOT_APPLICABLE',
}

const FormSubmissionStats: React.FC = () => {
    const [formSubmissionStats, setFormSubmissionStats] =
        useState<FormSubmissionStatsDTO | null>(null);

    // Fetching the statistics from backend API
    useEffect(() => {
        const fetchFormSubmissionStats = async () => {
            const data = await getAllFormSubmissionSummary();
            if (data) {
                setFormSubmissionStats(data);
            } else {
                console.log('error');
            }
        };
        fetchFormSubmissionStats();
    }, []);

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'IN_PROGRESS':
                return 'rgba(0, 123, 255, 0.7)';
            case 'SUBMITTED':
                return 'rgba(40, 167, 69, 0.7)';
            case 'OVERDUE':
                return 'rgba(220, 53, 69, 0.7)';
            case 'REOPENED':
                return 'rgba(253, 126, 20, 0.7)';
            case 'COMPLETED':
                return 'rgba(21, 87, 36, 0.7)';
            case 'NOT_APPLICABLE':
                return 'rgba(108, 117, 125, 0.7)';
            default:
                return 'rgba(176, 190, 197, 0.7)';
        }
    };

    const formatStatus = (status: string) => {
        if (!status) return '';

        return status
            .toLowerCase()
            .replace(/_/g, ' ')
            .replace(/\b\w/g, (char) => char.toUpperCase());
    };

    // Data for submissions by status (Doughnut Chart)
    const submissionsByStatusData = formSubmissionStats
        ? {
              labels: Object.keys(formSubmissionStats.submissionsByStatus),
              datasets: [
                  {
                      label: 'Assessment Status',
                      data: Object.values(
                          formSubmissionStats.submissionsByStatus
                      ),
                      backgroundColor: Object.keys(
                          formSubmissionStats.submissionsByStatus
                      ).map((status) => getStatusColor(status)),
                      borderColor: Object.keys(
                          formSubmissionStats.submissionsByStatus
                      ).map((status) => getStatusColor(status)),
                      borderWidth: 1,
                  },
              ],
          }
        : {};

    // Data for submissions by vendor (Bar Chart)
    const submissionsByVendorData = formSubmissionStats
        ? {
              labels: Object.values(
                  formSubmissionStats.submissionsByVendor
              ).map((vendor) => vendor.vendorName),
              datasets: [
                  {
                      label: 'Assessments per Vendor',
                      data: Object.values(
                          formSubmissionStats.submissionsByVendor
                      ).map((vendor) => vendor.submissionCount),
                      backgroundColor: 'rgba(54, 162, 235, 0.6)',
                      borderColor: 'rgba(54, 162, 235, 1)',
                      borderWidth: 1,
                      maxBarThickness: 15,
                      minBarLength: 2,
                  },
              ],
          }
        : {};

    // Data for submissions by vendor and status (Stacked Bar Chart)
    const submissionsByVendorAndStatusData = formSubmissionStats
        ? {
              labels: Object.values(
                  formSubmissionStats.submissionsByVendorAndStatus
              ).map((vendorStatus) => vendorStatus.vendorName),
              datasets: Object.keys(FormSubmissionStatus).map((status) => ({
                  label: formatStatus(status),
                  data: Object.values(
                      formSubmissionStats.submissionsByVendorAndStatus
                  ).map(
                      (vendorStatus) =>
                          vendorStatus.statusCounts[
                              status as FormSubmissionStatus
                          ]
                  ),
                  backgroundColor: getStatusColor(status),
                  stack: 'stack1',
                  maxBarThickness: 15,
                  minBarLength: 2,
              })),
          }
        : {};

    return (
        <Stack sx={{ width: '100%' }}>
            <Stack sx={{ width: '100%' }}>
                {formSubmissionStats ? (
                    <Stack>
                        <Typography
                            variant="h3"
                            style={{ textAlign: 'center' }}
                        >
                            Assessments Status
                        </Typography>
                        <Stack
                            style={{
                                display: 'flex',
                                justifyContent: 'space-evenly',
                                marginBottom: '20px',
                                flexDirection: 'row',
                                width: '100%',
                            }}
                        >
                            <Stack
                                style={{
                                    width: '40%',
                                    maxWidth: '500px',
                                }}
                            >
                                <Doughnut data={submissionsByStatusData} />
                            </Stack>
                            <Stack
                                style={{
                                    width: '40%',
                                    maxWidth: '500px',
                                }}
                                spacing={'10px'}
                            >
                                <Stack
                                    sx={{
                                        border: '1px solid #DDDDDD',
                                    }}
                                >
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ padding: '5px' }}
                                    >
                                        Total Assessments:{' '}
                                        {formSubmissionStats?.totalSubmissions}
                                    </Typography>
                                    <Divider />
                                    <Typography
                                        variant="subtitle1"
                                        sx={{ padding: '5px' }}
                                    >
                                        Assessments Due in Next Week:{' '}
                                        {
                                            formSubmissionStats?.submissionsDueInNextWeek
                                        }
                                    </Typography>
                                </Stack>

                                <table
                                    style={{
                                        width: '100%',
                                        borderCollapse: 'collapse',
                                        border: '1px solid #DDDDDD',
                                    }}
                                >
                                    <thead>
                                        <tr
                                            style={{
                                                backgroundColor: '#f2f2f2',
                                                textAlign: 'left',
                                            }}
                                        >
                                            <th
                                                style={{
                                                    padding: '10px',
                                                    borderBottom:
                                                        '1px solid #ddd',
                                                }}
                                            >
                                                <Typography variant="body1">
                                                    {'Status'}
                                                </Typography>
                                            </th>
                                            <th
                                                style={{
                                                    padding: '10px',
                                                    borderBottom:
                                                        '1px solid #ddd',
                                                }}
                                            >
                                                <Typography variant="body1">
                                                    {'Count'}
                                                </Typography>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(
                                            formSubmissionStats.submissionsByStatus
                                        ).map(([status, count]) => (
                                            <tr key={status}>
                                                <td
                                                    style={{
                                                        padding: '10px',
                                                        borderBottom:
                                                            '1px solid #ddd',
                                                    }}
                                                >
                                                    <Typography variant="body2">
                                                        {formatStatus(status)}
                                                    </Typography>
                                                </td>
                                                <td
                                                    style={{
                                                        padding: '10px',
                                                        borderBottom:
                                                            '1px solid #ddd',
                                                    }}
                                                >
                                                    {count}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Stack>
                        </Stack>
                    </Stack>
                ) : (
                    <p>Loading...</p>
                )}
            </Stack>
            <Stack
                sx={{
                    width: '100%',
                    paddingBottom: '20px',
                }}
            >
                {formSubmissionStats ? (
                    <Stack
                        style={{
                            width: '100%',
                            paddingBottom: '35px',
                            height: '500px',
                        }}
                    >
                        <Typography
                            variant="h3"
                            style={{ textAlign: 'center' }}
                        >
                            Assessment Status by Vendor
                        </Typography>
                        <Bar
                            data={submissionsByVendorAndStatusData}
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        ticks: {
                                            maxRotation: 45,
                                            minRotation: 0,
                                        },
                                    },
                                },
                            }}
                        />
                    </Stack>
                ) : (
                    <p>Loading...</p>
                )}
            </Stack>
            <Stack
                sx={{
                    width: '100%',
                    paddingBottom: '20px',
                }}
            >
                {formSubmissionStats ? (
                    <Stack
                        style={{
                            width: '100%',
                            height: '500px',
                            paddingBottom: '35px',
                        }}
                    >
                        <Typography
                            variant="h3"
                            style={{ textAlign: 'center' }}
                        >
                            Assessments per Vendor
                        </Typography>
                        <Bar
                            options={{
                                maintainAspectRatio: false,
                                scales: {
                                    x: {
                                        ticks: {
                                            maxRotation: 45,
                                            minRotation: 0,
                                        },
                                    },
                                },
                            }}
                            data={submissionsByVendorData}
                        />
                    </Stack>
                ) : (
                    <p>Loading...</p>
                )}
            </Stack>
        </Stack>
    );
};

export default FormSubmissionStats;
