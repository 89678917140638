import {
    Dialog,
    DialogContent,
    Divider,
    IconButton,
    Stack,
    styled,
} from '@mui/material';
import Typography from '../../atoms/Typography';
import { useState, useEffect, ChangeEvent } from 'react';
import {
    getFileForPreview,
    editFileUpload,
    sendCustomEmail,
    getVendorDocuments,
    downloadFileFromGCP,
} from '../../../services/api';
import theme from '../../../theme';
import { useRiskDocumentInfoColumns } from '../../../utils/helper';
import useSnackBar from '../../../utils/hooks';
import TypoInput from '../../molecules/TypoInput';
import { DocValues } from '../DocumentTab';
import DocumentViewer from '../DocumentViewer';
import EmailSender, { EmailFormData } from '../EmailSender';
import FileUploadModal from '../FileUploadModal';
import GenericModal from '../GenericModal';
import Button from '../../atoms/Button';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from '../DataTable';
import CustomSnackbar from '../../molecules/CustomSnackbar';

const StyledButton = styled(Button)({
    padding: '5px 8px',
    width: '160px',
});

const GenericModalStyle = {
    backgroundColor: theme.palette.structuralColors.white,
    padding: '10px',
    borderRadius: '5px',
    width: '25vw',
};

interface VendorDocumentsProps {
    onDocumentIdChange: (docValues: DocValues) => void;
    setRemarkTab: () => void;
    vendorId: string;
    userRole: string;
}

const VendorDocuments = (props: VendorDocumentsProps) => {
    const [fileUploadOpen, setFileUploadOpen] = useState(false);
    const [riskEvidenceDocument, setRiskEvidenceDocument] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [fileNameValue, setFileNameValue] = useState<string | null>(null);
    const [fileModal, setFileModal] = useState(false);
    const [isDocViewerLoading, setIsDocViewerLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const [errors, setErrors] = useState({
        linkTitle: '',
        linkUrl: '',
        displayName: '',
    });
    const [editFileData, setEditFileData] = useState({
        displayName: '',
    });
    const [documentId, setDocumentId] = useState('');
    const [documentType, setDocumentType] = useState('');
    const [editFileModalOpen, setEditFileModalOpen] = useState(false);
    const [editDocumentId, setEditDocumentId] = useState('');
    const [openEmailModel, setOpenEmailModel] = useState(false);

    const handleEmailModelOpen = () => {
        setOpenEmailModel(true);
    };
    const handleEmailModelClose = () => {
        setOpenEmailModel(false);
    };

    const handleEditFileModalOpen = () => {
        setEditFileModalOpen(!editFileModalOpen);
    };

    useEffect(() => {
        const currentValue: DocValues = {
            docId: documentId,
            type: documentType,
        };
        props.onDocumentIdChange(currentValue);
    }, [documentId, documentType]);

    const fetchDocumentData = async () => {
        const response = await getVendorDocuments(
            props.vendorId,
            paginationModel.page,
            paginationModel.pageSize
        );
        if (response) {
            setRiskEvidenceDocument(response.content);
            setRowCount(response.totalElements);
            setIsLoading(false);
        } else {
            console.log('error');
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchDocumentData();
    }, [paginationModel.page, paginationModel.pageSize]);

    const handleFileModalClose = () => {
        setFileUrl(null);
        setFileModal(false);
    };

    const handlePreviewOpen = async (id: string, title: string) => {
        setFileModal(true);
        setFileNameValue(title);
        const response = await getFileForPreview({ fileId: id });

        if (response) {
            const fileResource = new Blob([response.data], {
                type: 'application/pdf',
            });
            const fileUrl = URL.createObjectURL(fileResource);
            setFileUrl(fileUrl);
            setIsDocViewerLoading(false);
        } else {
            setFileModal(false);
            setMessage('Error occurred while fetching file');
            handleClick();
            setSuccess(false);
        }
    };

    const handleFileUploadToggle = (isOpen: boolean) => {
        setFileUploadOpen(isOpen);
    };

    const handleUploadComplete = (response: any) => {
        fetchDocumentData();
    };

    const handleFileNameChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldName: string
    ) => {
        const { value } = event.target;

        // Find the position of the last '.' in the current file name
        const lastDotIndex = editFileData.displayName.lastIndexOf('.');

        // Extract the extension and base name
        const originalExtension =
            lastDotIndex !== -1
                ? editFileData.displayName.substring(lastDotIndex) // Includes the dot (e.g., ".csv")
                : '';
        const baseName = lastDotIndex !== -1 ? value : editFileData.displayName;

        // Combine updated base name with the original extension
        const updatedDisplayName = `${baseName}${originalExtension}`;

        // Update state
        setEditFileData((prevState) => ({
            ...prevState,
            [fieldName]: updatedDisplayName,
        }));

        // Validate character count (min 5, max 250)
        const charCount = baseName.length;

        if (charCount < 5) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: 'File name must be at least 5 characters long.',
            }));
        } else if (charCount > 500) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: 'File name must be 500 characters or fewer.',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: '',
            }));
        }
    };

    const handleEditCompOpen = (id: string, type: string, title: string) => {
        if (type === 'FILE') {
            setEditFileData({
                displayName: title,
            });
            setEditDocumentId(id);
            handleEditFileModalOpen();
        }
    };

    const handleEditButtonClick = async (
        e: React.MouseEvent<HTMLButtonElement>,
        id: string,
        type: string
    ) => {
        e.stopPropagation();
        if (type === 'FILE') {
            const response = await editFileUpload({
                id: id,
                displayName: editFileData.displayName,
            });
            if (response) {
                fetchDocumentData();
            } else {
                console.log('error');
            }
            handleEditFileModalOpen();
        }
    };

    const handleEmailSend = async (formData: EmailFormData) => {
        const data = {
            recipientEmail: formData.toEmail,
            attachmentLink: formData.attachmentLink,
            subject: formData.emailSubject,
            emailBody: formData.emailBody,
            contactName: formData.contactName,
            contactEmail: formData.contactEmail,
        };

        const response = await sendCustomEmail(data);
        if (response.success) {
            setMessage(response.message);
            handleClick();
            setSuccess(true);
        } else {
            setMessage('Failed to send email');
            handleClick();
            setSuccess(false);
        }
    };

    const handleFileDownload = async (fileId: string, fileName: string) => {
        setMessage('File download started.');
        handleClick();
        setSuccess(true);
        const response = await downloadFileFromGCP(fileId);
        if (response) {
            setMessage('File download successful.');
            handleClick();
            setSuccess(true);

            const blob = new Blob([response.data]);
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            link.remove();

            window.URL.revokeObjectURL(downloadUrl);
        } else {
            setMessage('Error downloading file');
            handleClick();
            setSuccess(false);
        }
    };

    return (
        <Stack spacing={'20px'} padding={'10px'}>
            <Stack
                spacing={'15px'}
                sx={{
                    border: `1px solid ${theme.palette.structuralColors.gray}`,
                    padding: '15px 10px',
                    borderRadius: '3px',
                }}
            >
                <Stack
                    sx={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        color={theme.palette.text.highEmphasis}
                    >
                        {'Uploaded Document'}
                    </Typography>
                    {props.userRole === 'ADMIN' ? (
                        <StyledButton
                            variant="outlined"
                            onClick={() => handleFileUploadToggle(true)}
                        >
                            <Typography variant="caption1">
                                {'Upload File'}
                            </Typography>
                        </StyledButton>
                    ) : (
                        <></>
                    )}
                </Stack>
                <Divider />
                <DataTable
                    columns={useRiskDocumentInfoColumns(
                        setDocumentId,
                        setDocumentType,
                        props,
                        handleEditCompOpen,
                        handlePreviewOpen,
                        handleFileDownload
                    )}
                    rowsData={riskEvidenceDocument || []}
                    isLoading={isLoading}
                    checkboxSelection={false}
                    resizeThrottleMs={190}
                    rowCount={rowCount}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        },
                    }}
                />
                {props.userRole === 'ADMIN' ? (
                    <Stack
                        direction={'row'}
                        sx={{ justifyContent: 'space-between' }}
                    >
                        <StyledButton
                            variant="contained"
                            onClick={handleEmailModelOpen}
                        >
                            <Typography variant="caption1">
                                {'Send Email'}
                            </Typography>
                        </StyledButton>
                    </Stack>
                ) : (
                    <></>
                )}
                <EmailSender
                    open={openEmailModel}
                    handleEmailSend={(formData) => handleEmailSend(formData)}
                    onClose={handleEmailModelClose}
                />
            </Stack>

            <FileUploadModal
                open={fileUploadOpen}
                onToggle={handleFileUploadToggle}
                id={props.vendorId}
                onUploadComplete={handleUploadComplete}
                uploadFrom={'Vendor'}
            />
            <Dialog
                open={fileModal}
                onClose={handleFileModalClose}
                maxWidth="lg"
                fullWidth
            >
                <DialogContent>
                    {fileUrl ? (
                        <DocumentViewer
                            filePreview={{ fileUrl, fileName: fileNameValue }}
                            loading={isDocViewerLoading}
                        />
                    ) : null}
                </DialogContent>
            </Dialog>
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
            <GenericModal open={editFileModalOpen} style={GenericModalStyle}>
                <Stack spacing={'10px'}>
                    <Stack
                        sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="subtitle1">
                            {'Edit File Name'}
                        </Typography>
                        <IconButton onClick={handleEditFileModalOpen}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Stack
                        direction={'row'}
                        sx={{
                            alignItems: 'flex-end',
                            flexDirection: 'row',
                        }}
                        spacing={'5px'}
                    >
                        <TypoInput
                            labelvalue="File Name"
                            placeholder={'File Name'}
                            value={editFileData.displayName.substring(
                                0,
                                editFileData.displayName.lastIndexOf('.')
                            )}
                            onChange={(e) =>
                                handleFileNameChange(e, 'displayName')
                            }
                            manditoryfield="true"
                        />
                        <Typography variant="body1">
                            {'.'}
                            {editFileData.displayName.substring(
                                editFileData.displayName.lastIndexOf('.') + 1
                            )}
                        </Typography>
                    </Stack>
                    {errors.displayName && (
                        <Typography variant="caption2" color={'red'}>
                            {errors.displayName}
                        </Typography>
                    )}
                    <Button
                        variant="outlined"
                        onClick={(e) =>
                            handleEditButtonClick(e, editDocumentId, 'FILE')
                        }
                        disabled={Object.values(errors).some(
                            (error) => error !== ''
                        )}
                    >
                        <Typography variant="caption1">{'Save'}</Typography>
                    </Button>
                </Stack>
            </GenericModal>
        </Stack>
    );
};

export default VendorDocuments;
