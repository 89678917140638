export const validateDomainURLFormat = (value: string): boolean => {
    const domainURLRegex = /^(https?:\/\/)[\w\-]+(\.[\w\-]+)+([\/\w\-._~:?#[\]@!$&'()*+,;=]*)?$/;
    return domainURLRegex.test(value);
};

export const validateTitleFormat = (value: string): boolean => {
    const titleRegex = /^[a-zA-Z0-9\s.,!?()\-\:;'"@#$%^&*\[\]{}\/\\|`~]+$/;
    return titleRegex.test(value);
};

export const validateTextareaFormat = (value: string): boolean => {
    const textareaRegex =
        /^[a-zA-Z0-9\s\.,;:'"\(\)\[\]\{\}\-_\+=\*&%\$#@!\?\/\\]*$/;
    return textareaRegex.test(value);
};

export const validateEmailFormat = (value: string): boolean => {
    const emailRegex =
        /^(?!.*\s)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(value);
};

export const validateMeeshoEmailFormat = (value: string): boolean => {
    const meeshoEmailRegex = /^[a-zA-Z0-9._%+-]+@meesho\.com$/;
    return meeshoEmailRegex.test(value);
};