import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '../../components/atoms/Typography';
import { useEffect, useState } from 'react';
import { useVendorAuth } from '../../context/vendorAuthContext';
import { vendorGetAccessToken } from '../../services/api';
import { Stack, Box, styled } from '@mui/material';
import Button from '../../components/atoms/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Icon from '../../components/atoms/Icon';
import theme from '../../theme';
import Logo from '../../../public/assets/icons/logoPlain.png';
import LoadingAnimation from '../../../public/assets/icons/dots-loading.gif';

const StyledStack = styled(Stack)({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.structuralColors.white,
});

const LogoStyle = {
    width: '200px',
    height: '200px',
};

const VendorVerifyPage = () => {
    const { login } = useVendorAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [code, setCode] = useState<string | null>(null);
    const [isVerifying, setIsVerifying] = useState(false);

    const getToken = async () => {
        if (!code) return;

        setIsVerifying(true);
        const response = await vendorGetAccessToken({
            magicToken: code,
        });
        if (response && response.jwt !== null) {
            const data = response.jwt;
            login(data);
            navigate('/v/vendordashboard');
        } else {
            navigate('/vendorlogin', { state: { loggedOut: true } });
        }
        setIsVerifying(false);
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('code');

        if (token !== null) {
            setCode(token);
        }
    }, [location]);

    return (
        <Stack
            direction={'column'}
            sx={{ width: '100%', height: '100vh' }}
            position={'relative'}
        >
            <Box
                width={'100%'}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Icon src={Logo} style={LogoStyle} alt={'Logo'} />
            </Box>
            <StyledStack>
                <Stack
                    sx={{
                        padding: '24px',
                        marginTop: '20vh',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {isVerifying ? (
                        <></>
                    ) : (
                        <Typography
                            variant="body1"
                            sx={{ marginBottom: '16px' }}
                        >
                            {code ? '' : 'Invalid login link. Please try again'}
                        </Typography>
                    )}
                    {code ? (
                        <Stack>
                            {isVerifying ? (
                                <Stack
                                    sx={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Icon
                                        src={LoadingAnimation}
                                        style={{
                                            height: '50px',
                                            width: '50px',
                                        }}
                                    />
                                    <Typography variant="body1">
                                        {'Verifying'}
                                    </Typography>
                                </Stack>
                            ) : (
                                <Button
                                    onClick={getToken}
                                    disabled={isVerifying}
                                    variant="contained"
                                    sx={{
                                        backgroundColor: isVerifying
                                            ? '#9e9e9e'
                                            : '#4caf50',
                                        color: '#fff',
                                        '&:hover': {
                                            backgroundColor: isVerifying
                                                ? '#9e9e9e'
                                                : '#388e3c',
                                        },
                                        transition:
                                            'background-color 0.3s ease',
                                        width: '30vw',
                                    }}
                                >
                                    <Typography variant="body1">
                                        {'Click to Login'}
                                    </Typography>
                                </Button>
                            )}
                        </Stack>
                    ) : (
                        <Stack spacing={2}>
                            <Button
                                onClick={() => navigate('/vendorlogin')}
                                startIcon={<ArrowBackIcon />}
                                variant="outlined"
                                sx={{
                                    color: '#1976d2',
                                    borderColor: '#1976d2',
                                    '&:hover': {
                                        backgroundColor: '#e3f2fd',
                                        borderColor: '#115293',
                                    },
                                    transition: 'all 0.3s ease',
                                }}
                            >
                                <Typography variant="body1">
                                    {'Go back to login'}
                                </Typography>
                            </Button>
                        </Stack>
                    )}
                </Stack>
            </StyledStack>
        </Stack>
    );
};

export default VendorVerifyPage;
