import axios from 'axios';
import React, { createContext, useContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

//const signOutAxiosInstance = axios.create();

interface VendorAuthContextType {
    isVendorAuthenticated: boolean;
    login: (token: string) => void;
    logout: () => void;
}

const VendorAuthContext = createContext<VendorAuthContextType>({
    isVendorAuthenticated: false,
    login: () => {},
    logout: () => {},
});

export function useVendorAuth(): VendorAuthContextType {
    const context = useContext(VendorAuthContext);
    if (!context) {
        throw new Error(
            'useVendorAuth must be used within an VendorAuthContextProvider'
        );
    }
    return context;
}

interface VendorAuthContextProviderProps {
    children: ReactNode;
}

export function VendorAuthContextProvider({
    children,
}: VendorAuthContextProviderProps): JSX.Element {
    const navigate = useNavigate();
    const [isVendorAuthenticated, setIsVendorAuthenticated] = useState<boolean>(
        () => {
            const token = localStorage.getItem('accessToken');
            return !!token;
        }
    );
    const [accessToken, setAccessToken] = useState<string | null>(() =>
        localStorage.getItem('accessToken')
    );

    const signOut = () => {
        const token = accessToken;
        if (token) {
            localStorage.clear();
            setIsVendorAuthenticated(false);
            setAccessToken(null);
            navigate('/vendorlogin', { state: { loggedOut: true } });
        }
    };

    const login = (token: string): void => {
        localStorage.setItem('accessToken', token);
        setAccessToken(token);
        setIsVendorAuthenticated(true);
    };

    const logout = (): void => {
        setIsVendorAuthenticated(false);
        signOut();
    };

    const value: VendorAuthContextType = {
        isVendorAuthenticated,
        login,
        logout,
    };

    setLogoutFunction(logout);
    setAccessTokenFunction(setAccessToken);

    return (
        <VendorAuthContext.Provider value={value}>
            {children}
        </VendorAuthContext.Provider>
    );
}

let vendorLogoutFunction: () => void = () => {};
let accessTokenFunction: (token: string | null) => void = () => {};

export function setLogoutFunction(logout: () => void) {
    vendorLogoutFunction = logout;
}

export function setAccessTokenFunction(
    setToken: (token: string | null) => void
) {
    accessTokenFunction = setToken;
}

export { vendorLogoutFunction, accessTokenFunction };
