import { Box, IconButton, Stack, styled, Typography } from '@mui/material';
import MainTemplate from '../../components/templates/MainTemplate';
import TableHeader from '../../components/organisms/TableHeader';
import { getAllFormsColumns, StyledGridOverlay } from '../../utils/helper';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import TypoInput from '../../components/molecules/TypoInput';
import GenericModal from '../../components/organisms/GenericModal';
import theme from '../../theme';
import { ChangeEvent, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../../components/atoms/Button';
import useSnackBar from '../../utils/hooks';
import { createForm, getAllForms } from '../../services/api';
import { validateTitleFormat } from '../../utils/validations';
import { useNavigate } from 'react-router-dom';
import ClientSideDataTable from '../../components/organisms/ClientSideDataTable';
import CustomSnackbar from '../../components/molecules/CustomSnackbar';

const StyledButton = styled(Button)({
    padding: '5px 8px',
    width: '100px',
});

const FormPage = () => {
    const navigate = useNavigate();
    const [formList, setFormList] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [formData, setFormData] = useState({
        formName: '',
        formDescription: '',
    });
    const [errors, setErrors] = useState({ formName: '', formDescription: '' });
    const [isLoading, setIsLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const [fetchNewData, setFetchNewData] = useState(false);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => {
        setModalOpen(false);
        setFormData({ formName: '', formDescription: '' });
        setErrors({ formName: '', formDescription: '' });
    };

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const reports = await getAllForms();
            if (reports) {
                setFormList(reports);
                //setRowCount(reports.totalElements);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [fetchNewData]);

    const handleValueChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldName: string
    ) => {
        let charLimit;
        const { value } = event.target;

        setFormData((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }));

        const charCount = value.length;
        if ([fieldName].toString() === 'formName') {
            charLimit = 100;
        } else {
            charLimit = 300;
        }

        if ((value && !validateTitleFormat(value)) || charCount > charLimit) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: 'Invalid Input',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: '',
            }));
        }
    };

    const handleCreateForm = async () => {
        const response = await createForm({
            title: formData.formName,
            description: formData.formDescription,
        });

        if (response) {
            setMessage('Form created successfully');
            handleClick();
            setSuccess(true);
        } else {
            setMessage('Failed to create form. Please try again.');
            handleClick();
            setSuccess(false);
        }
        setFetchNewData(!fetchNewData);
        handleModalClose();
    };

    const ProfileTableHeaderOption = [
        {
            id: 1,
            label: 'Create Form',
            icon: <ControlPointIcon />,
            onClick: () => handleModalOpen(),
        },
    ];

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1">
                        {'No Documents Found'}
                    </Typography>
                </Box>
            </StyledGridOverlay>
        );
    }

    return (
        <MainTemplate
            main={
                <Stack sx={{}}>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <TableHeader
                            headerName={'List of Forms'}
                            searchQuery={''}
                            setSearchQuery={() => {}}
                            handleFilterChange={() => {}}
                            menuItems={ProfileTableHeaderOption}
                            filterList={[]}
                            searchVisible={false}
                            searchFieldPlaceholder={'Search by Form Name'}
                        />
                        <ClientSideDataTable
                            columns={getAllFormsColumns((row) => {
                                const { id } = row;
                                navigate(`/u/generatePage?id=${id}`);
                            })}
                            rowsData={formList || []}
                            isLoading={isLoading}
                            checkboxSelection={false}
                            slotProps={{
                                loadingOverlay: {
                                    variant: 'skeleton',
                                    noRowsVariant: 'skeleton',
                                },
                            }}
                            slots={{
                                noRowsOverlay: CustomNoRowsOverlay,
                            }}
                            resizeThrottleMs={190}
                            pageSizeOptions={[5, 10, 25, 50, 75, 100]}
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: 10, page: 0 },
                                },
                            }}
                        />
                    </Box>
                    <GenericModal
                        open={modalOpen}
                        style={{
                            backgroundColor:
                                theme.palette.structuralColors.white,
                            padding: '10px',
                            borderRadius: '5px',
                            width: '25vw',
                        }}
                    >
                        <Stack spacing={'10px'}>
                            <Stack
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="subtitle1">
                                    {'Create a new Form'}
                                </Typography>
                                <IconButton onClick={handleModalClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Stack>
                            <TypoInput
                                labelvalue="Form Name"
                                placeholder={'Form Name'}
                                value={formData.formName}
                                onChange={(e) =>
                                    handleValueChange(e, 'formName')
                                }
                                manditoryfield="true"
                            />
                            {errors.formName && (
                                <Typography variant="caption2" color={'red'}>
                                    {errors.formName}
                                </Typography>
                            )}
                            <TypoInput
                                labelvalue="Form Description"
                                placeholder={'Form Description'}
                                value={formData.formDescription}
                                onChange={(e) =>
                                    handleValueChange(e, 'formDescription')
                                }
                                manditoryfield="true"
                            />
                            {errors.formDescription && (
                                <Typography variant="caption2" color={'red'}>
                                    {errors.formDescription}
                                </Typography>
                            )}
                            <Stack
                                direction={'row'}
                                spacing={'10px'}
                                sx={{ paddingTop: '20px' }}
                            >
                                <StyledButton
                                    variant="outlined"
                                    onClick={handleModalClose}
                                >
                                    <Typography variant="caption1">
                                        {'Cancel'}
                                    </Typography>
                                </StyledButton>
                                <StyledButton
                                    variant="outlined"
                                    onClick={handleCreateForm}
                                    disabled={
                                        errors.formName !== '' ||
                                        formData.formName.trim().length < 10
                                    }
                                >
                                    <Typography variant="caption1">
                                        {'Save'}
                                    </Typography>
                                </StyledButton>
                            </Stack>
                        </Stack>
                    </GenericModal>
                    <CustomSnackbar
                        open={openSnackBar}
                        message={message}
                        success={success}
                        onClose={handleClose}
                        autoHideDuration={2500}
                    />
                </Stack>
            }
        />
    );
};

export default FormPage;
