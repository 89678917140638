import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { List, Stack, Typography } from '@mui/material';
import { AppMenuItems } from '../../../utils/constants';
import AppMenuItem from './AppMenuItem';
import theme from '../../../theme';

type Permission = {
    name: string;
    allowedRoles: string[];
};

// Define the permissions configuration
const menuPermissions: Permission[] = [
    {
        name: 'Dashboard',
        allowedRoles: ['ADMIN', 'USER'], // Everyone can see dashboard
    },
    {
        name: 'TPRM',
        allowedRoles: ['ADMIN', 'USER'], // Only admin and manager can see risks
    },
    {
        name: 'Assessments',
        allowedRoles: ['ADMIN', 'USER'], // Only admin and manager can see risks
    },
    {
        name: 'Form Templates',
        allowedRoles: ['ADMIN'], // Only admin can see settings
    },
    {
        name: 'Risks',
        allowedRoles: ['ADMIN', 'USER'], // Only admin and manager can see risks
    },
    {
        name: 'Risk Register',
        allowedRoles: ['ADMIN', 'USER'], // Everyone can see documents
    },
    {
        name: 'Documents',
        allowedRoles: ['ADMIN', 'USER'], // Everyone can see documents
    },
    {
        name: 'Policies',
        allowedRoles: ['ADMIN', 'USER'], // Admin and regular users can see forms
    },
    {
        name: 'Procedures',
        allowedRoles: ['ADMIN', 'USER'], // Only admin can see settings
    },
    {
        name: 'Mandatory Documents',
        allowedRoles: ['ADMIN', 'USER'], // Admin and regular users can see forms
    },
    {
        name: 'Settings',
        allowedRoles: ['ADMIN'], // Only admin can see settings
    },
    {
        name: 'Manage Users',
        allowedRoles: ['ADMIN'], // Only admin can see settings
    },
    {
        name: 'Manage Templates',
        allowedRoles: ['ADMIN'], // Only admin can see settings
    },
];

// Function to decode JWT token
const decodeJWT = (token: string): any => {
    try {
        return JSON.parse(atob(token.split('.')[1]));
    } catch (error) {
        console.error('Error decoding JWT:', error);
        return null;
    }
};

// Function to check if a menu item should be visible
const isMenuItemVisible = (menuName: string, userRole: string): boolean => {
    const permission = menuPermissions.find((p) => p.name === menuName);
    return permission ? permission.allowedRoles.includes(userRole) : false;
};

const drawerWidth = '250px';

const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: '60px',
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px 1px',
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));

const StyledList = styled(List)({
    width: '100%',
});

interface SidebarProps {
    open: boolean;
    onToggle: (isOpen: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ open, onToggle }) => {
    const [userRole, setUserRole] = React.useState<string>('');

    React.useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            const decodedToken = decodeJWT(token);
            if (decodedToken && decodedToken.role) {
                setUserRole(decodedToken.role);
            }
        }
    }, []);

    const handleDrawerOpen = () => {
        onToggle(true);
    };

    const handleDrawerClose = () => {
        onToggle(false);
    };

    // Filter menu items based on user role
    const filterMenuItems = (
        items: typeof AppMenuItems
    ): typeof AppMenuItems => {
        return items
            .filter((item) => isMenuItemVisible(item.name, userRole))
            .map((item) => ({
                ...item,
                items: item.items ? filterMenuItems(item.items) : undefined,
            }));
    };

    const filteredMenuItems = filterMenuItems(AppMenuItems);

    return (
        <Box sx={{ display: 'flex' }}>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader
                    sx={{
                        background: `linear-gradient(0.25turn,#05bdcd, #fff)`,
                    }}
                >
                    <IconButton
                        color="inherit"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            marginLeft: '3px',
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {open && (
                        <Stack
                            direction={'row'}
                            style={{
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '93%',
                            }}
                        >
                            <Typography
                                variant="h3"
                                color={theme.palette.text.highEmphasis}
                            >
                                {'CompliSys'}
                            </Typography>
                            <IconButton onClick={handleDrawerClose}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </Stack>
                    )}
                </DrawerHeader>
                <StyledList disablePadding>
                    {filteredMenuItems.map((item, index) => (
                        <AppMenuItem {...item} key={index} openState={open} />
                    ))}
                </StyledList>
            </Drawer>
        </Box>
    );
};

export default Sidebar;
