import {
    Divider,
    InputAdornment,
    Stack,
    styled,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Search } from '@mui/icons-material';
import theme from '../../../theme';
import InputField from '../../atoms/InputField';
import DropdownMenu from '../DropdownMenu';
import ArrowDown from '../../../../public/assets/icons/arrowdown.svg';
import Icon from '../../atoms/Icon';
import { Action } from '../../../utils/constants';
import {
    DecodedJWT,
    DropdownMenuItemProps,
    FilterField,
} from '../../../utils/types';
import FilterPopup from '../FilterPopup';
import { jwtDecode } from 'jwt-decode';

const StyledStack = styled(Stack)({
    height: '6vh',
    flexGrow: '1',
    backgroundColor: theme.palette.structuralColors.white,
    padding: '1vw',
    borderTopLeftRadius: '6px',
    borderTopRightRadius: '6px',
    border: `1px solid ${theme.palette.structuralColors.stroke1}`,
});

interface ReportTableHeaderProps {
    searchFieldPlaceholder: string;
    headerName: string;
    searchQuery: string;
    setSearchQuery: (query: string) => void;
    handleFilterChange: (statusList: string[]) => void;
    menuItems: DropdownMenuItemProps[];
    filterList: FilterField[];
    searchVisible: boolean;
}

const TableHeader = (props: ReportTableHeaderProps) => {
    const [searchValue, setSearchValue] = useState(props.searchQuery);

    let token;
    if (localStorage.getItem('token')) {
        token = localStorage.getItem('token');
    }

    const { role } = jwtDecode<DecodedJWT>(token);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSearchValue(value);
        props.setSearchQuery(value);
    };

    return (
        <StyledStack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
        >
            <Typography variant="h3" color={theme.palette.text.highEmphasis}>
                {props.headerName}
            </Typography>
            <Stack direction={'row'} spacing={'1.5vw'}>
                {props.searchVisible && (
                    <>
                        <InputField
                            width="28vw"
                            height="40px"
                            value={searchValue}
                            placeholder={props.searchFieldPlaceholder}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Search sx={{ marginBottom: '2px' }} />
                                </InputAdornment>
                            }
                            onChange={handleChange}
                        />
                        <FilterPopup
                            filterFields={props.filterList}
                            onFilterChange={function (
                                statusList: string[]
                            ): void {
                                props.handleFilterChange(statusList);
                            }}
                        />
                    </>
                )}
                {role === 'ADMIN' ? (
                    <Stack direction={'row'} spacing={'1.5vw'}>
                        <Divider orientation="vertical" flexItem />
                        <DropdownMenu
                            variant="outlined"
                            iconButtonContent={
                                <Typography
                                    variant="caption1"
                                    color={theme.palette.primary[500]}
                                >
                                    {Action}
                                </Typography>
                            }
                            style={{ height: '40px', padding: '5px 15px' }}
                            endIcon={<Icon alt="ArrowDown" src={ArrowDown} />}
                            menuItems={props.menuItems}
                        />
                    </Stack>
                ) : (
                    <></>
                )}
            </Stack>
        </StyledStack>
    );
};

export default TableHeader;
