import { Box, Divider, Stack, styled } from '@mui/material';
import Typography from '../../components/atoms/Typography';
import theme from '../../theme';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import ClientSideDataTable from '../../components/organisms/ClientSideDataTable';
import {
    getFormSubmission,
    getFormSubmissionsByUserId,
} from '../../services/api';
import { StyledGridOverlay } from '../../utils/helper';
import { jwtDecode } from 'jwt-decode';
import { useVendorAuth } from '../../context/vendorAuthContext';
import Button from '../../components/atoms/Button';
import CustomSnackbar from '../../components/molecules/CustomSnackbar';
import useSnackBar from '../../utils/hooks';

const TableRowStyleStack = styled(Stack)({
    justifyContent: 'center',
    height: '100%',
});

interface DecodedJWT {
    role: string;
    userId: string;
}

const VendorDashboardPage = () => {
    const { logout } = useVendorAuth();
    const navigate = useNavigate();
    const [formSubmissions, setFormSubmissions] = useState<any[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();

    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('accessToken');
            const { vendorId } = jwtDecode<DecodedJWT>(token);

            const response = await getFormSubmissionsByUserId(vendorId);

            if (response) {
                setFormSubmissions(response);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleFormClick = useCallback(
        (formSubmissionId: string, formId: string) => async () => {
            const response = await getFormSubmission(formSubmissionId);

            if (response.message === 'success') {
                navigate(`/v/formSubmission?id=${formSubmissionId}`);
            } else {
                if (response.message === 'Overdue') {
                    setMessage(
                        'The due date for this form has passed. Please contact the administrator.'
                    );
                    handleClick();
                    setSuccess(false);
                } else if (response.message === 'Unauthorised') {
                    setMessage('Unauthorised to access the form.');
                    handleClick();
                    setSuccess(false);
                } else {
                    setMessage('An error occurred.');
                    handleClick();
                    setSuccess(false);
                }
            }
        },
        []
    );

    const columns = useMemo<GridColDef[]>(
        () => [
            {
                field: 'assessmentName',
                headerName: 'Assessment Name',
                flex: 2,
                renderCell: (params: GridRenderCellParams) => {
                    return (
                        <Stack
                            sx={{ justifyContent: 'center', height: '100%' }}
                        >
                            <Button
                                onClick={handleFormClick(
                                    params.row.id,
                                    params.row.formId
                                )}
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'inherit',
                                    },
                                    textWrap: 'wrap',
                                    width: '100%',
                                    justifyContent: 'flex-start',
                                    padding: '0px',
                                }}
                                disableElevation
                                disableRipple
                            >
                                <Typography
                                    variant="body2"
                                    color={theme.palette.accentColors.blue}
                                    sx={{ textTransform: 'none' }}
                                >
                                    <u>{params.value}</u>
                                </Typography>
                            </Button>
                        </Stack>
                    );
                },
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 1,
                renderCell: (params: GridRenderCellParams) => {
                    const formatStatus = (status: string) => {
                        if (!status) return '';

                        return status
                            .toLowerCase()
                            .replace(/_/g, ' ')
                            .replace(/\b\w/g, (char) => char.toUpperCase());
                    };
                    return (
                        <TableRowStyleStack>
                            <Typography variant="body2">
                                {formatStatus(params.value)}
                            </Typography>
                        </TableRowStyleStack>
                    );
                },
            },
            {
                field: 'endDate',
                headerName: 'End Date',
                flex: 1,
                renderCell: (params) => (
                    <TableRowStyleStack>
                        <Typography variant="body2">{params.value}</Typography>
                    </TableRowStyleStack>
                ),
            },
            {
                field: 'progress',
                headerName: 'Progress',
                flex: 1,
                renderCell: (params) => (
                    <Stack
                        direction="row"
                        spacing={'5px'}
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            height: '100%',
                        }}
                    >
                        <Typography variant="body2">{`${params.row.totalAnswerValue} of ${params.row.totalQuestion}`}</Typography>
                        <progress
                            value={params.row.totalAnswerValue}
                            max={params.row.totalQuestion}
                            style={{ width: '100px' }}
                        />
                    </Stack>
                ),
            },
            {
                field: 'contact',
                headerName: 'User',
                flex: 2,
                renderCell: (params) => (
                    <TableRowStyleStack>
                        <Typography variant="body2">{params.value}</Typography>
                    </TableRowStyleStack>
                ),
            },
        ],
        [handleFormClick]
    );

    const rows = formSubmissions.map((submission) => ({
        id: submission.id,
        assessmentName: submission.assessmentName,
        startDate: dayjs(submission.startDate).format('DD/MM/YYYY'),
        endDate: dayjs(submission.dueDate).format('DD/MM/YYYY'),
        totalAnswerValue: submission.totalAnswerValue,
        status: submission.status,
        totalQuestion: submission.totalQuestion,
        contact: submission.respondents
            .map((resp: any) => `${resp.name}\r\n(${resp.email})`)
            .join(', '),
        formId: submission.formId,
    }));

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1">
                        {'No Assessments Found'}
                    </Typography>
                </Box>
            </StyledGridOverlay>
        );
    }
    return (
        <Stack spacing={'20px'} padding={'10px'}>
            <Stack
                spacing={'15px'}
                sx={{
                    border: `1px solid ${theme.palette.structuralColors.gray}`,
                    padding: '15px 10px',
                    borderRadius: '3px',
                }}
            >
                <Stack
                    direction={'row'}
                    sx={{
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        variant="subtitle1"
                        color={theme.palette.text.highEmphasis}
                    >
                        {'Forms'}
                    </Typography>
                    <Button onClick={() => logout()} variant="contained">
                        <Typography variant="caption1">{'Logout'}</Typography>
                    </Button>
                </Stack>

                <Divider />
                <Stack
                    sx={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <ClientSideDataTable
                        columns={columns}
                        rowsData={rows}
                        isLoading={isLoading}
                        checkboxSelection={false}
                        hideFooter={false}
                        slotProps={{
                            loadingOverlay: {
                                variant: 'skeleton',
                                noRowsVariant: 'skeleton',
                            },
                        }}
                        slots={{
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        resizeThrottleMs={190}
                        pageSizeOptions={[5, 10, 25, 50, 75, 100]}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 10, page: 0 },
                            },
                        }}
                    />
                </Stack>
            </Stack>
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={2000}
            />
        </Stack>
    );
};

export default VendorDashboardPage;
