import { Stack } from '@mui/material';
import Typography from '../../components/atoms/Typography';
import InputField from '../../components/atoms/InputField';
import Button from '../../components/atoms/Button';
import { ChangeEvent, useState } from 'react';
import { vendorLoginRequest } from '../../services/api';
import theme from '../../theme';
import useSnackBar from '../../utils/hooks';
import {
    validateEmailFormat,
    vendorLoginInstructions,
} from '../../utils/constants';
import CustomSnackbar from '../../components/molecules/CustomSnackbar';
import { useLocation } from 'react-router-dom';

const VendorLoginPage = () => {
    const location = useLocation();
    const [formData, setFormData] = useState({ email: '' });
    const [errors, setErrors] = useState({ email: '' });
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const [logoutMessage, setLogoutMessage] = useState<string | null>(
        location.state?.loggedOut ? 'Please login again.' : null
    );

    const handleValueChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldName: string
    ) => {
        let { value } = event.target;

        value = value.trim();

        setFormData((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }));

        const charCount = value.length;

        if ((value && !validateEmailFormat(value)) || charCount > 150) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: 'Invalid Email',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: '',
            }));
        }
    };

    const handleLoginRequest = async () => {
        const response = await vendorLoginRequest({
            userEmail: formData.email,
        });
        if (response) {
            setMessage('Link has been sent to the Email');
            handleClick();
            setSuccess(true);
            setFormData({ email: '' });
        } else {
            setMessage('Link has been sent to the Email');
            handleClick();
            setSuccess(true);
            setFormData({ email: '' });
        }
    };

    const buttonDisabled = () => {
        return formData.email && !errors.email;
    };

    return (
        <Stack
            sx={{
                height: '100vh',
                width: '100%',
                justifyContent: 'flex-start',
                alignItems: 'center',
            }}
        >
            <Stack
                direction={'column'}
                sx={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '32vw',
                    marginTop: '35vh',
                }}
                spacing={'30px'}
            >
                <Typography variant="h1" color={theme.palette.primary[500]}>
                    {'Vendor Login'}
                </Typography>
                <Stack
                    sx={{ justifyContent: 'flex-start', width: '32vw' }}
                    spacing={'3px'}
                >
                    <InputField
                        size="small"
                        label="Enter Email"
                        name="email"
                        value={formData.email}
                        onChange={(e) => handleValueChange(e, 'email')}
                        fullWidth
                    />
                    {errors.email && (
                        <Typography variant="caption2" color={'red'}>
                            {errors.email}
                        </Typography>
                    )}
                </Stack>
                <Button
                    variant="outlined"
                    onClick={handleLoginRequest}
                    sx={{ padding: '5px 8px', width: '200px' }}
                    disabled={!buttonDisabled()}
                >
                    <Typography variant="body1">{'Login'}</Typography>
                </Button>
            </Stack>
            <Stack
                sx={{
                    backgroundColor: theme.palette.structuralColors.stroke1,
                    padding: '16px',
                    marginTop: '16px',
                    width: '30vw',
                    border: `1px solid ${theme.palette.grey[300]}`,
                    borderRadius: '8px',
                }}
            >
                <Typography variant="body1" color={theme.palette.primary[500]}>
                    Instructions:
                </Typography>
                <ul style={{ paddingLeft: '16px', marginTop: '8px' }}>
                    {vendorLoginInstructions.map((instruction, index) => (
                        <li key={index}>
                            <Typography
                                variant="caption2"
                                color={theme.palette.text.primary}
                            >
                                {instruction}
                            </Typography>
                        </li>
                    ))}
                </ul>
            </Stack>
            <CustomSnackbar
                open={openSnackBar || !!logoutMessage}
                message={logoutMessage || message}
                success={success || !!logoutMessage}
                onClose={() => {
                    handleClose();
                    setLogoutMessage(null);
                }}
                autoHideDuration={2500}
            />
        </Stack>
    );
};

export default VendorLoginPage;
