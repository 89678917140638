import {
    Box,
    Dialog,
    DialogContent,
    IconButton,
    Stack,
    styled,
} from '@mui/material';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';
import AddLinkIcon from '@mui/icons-material/AddLink';
import GenericModal from '../GenericModal';
import TypoInput from '../../molecules/TypoInput';
import { ChangeEvent, useEffect, useState } from 'react';
import theme from '../../../theme';
import CloseIcon from '@mui/icons-material/Close';
import {
    StyledGridOverlay,
    useDocumentInfoColumns,
} from '../../../utils/helper';
import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import {
    addDocumentLink,
    downloadFileFromGCP,
    editDocumentLink,
    editFileUpload,
    getAllRegistryDocuments,
    getFileForPreview,
} from '../../../services/api';
import {
    validateDomainURLFormat,
    validateTitleFormat,
} from '../../../utils/validations';
import ClientSideDataTable from '../ClientSideDataTable';
import useSnackBar from '../../../utils/hooks';
import FileUploadModal from '../FileUploadModal';
import DocumentViewer from '../DocumentViewer';
import CustomSnackbar from '../../molecules/CustomSnackbar';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const ErrorStyle = {
    marginLeft: '14px',
};

const StyledButton = styled(Button)({
    padding: '5px 8px',
});

const GenericModalStyle = {
    backgroundColor: theme.palette.structuralColors.white,
    padding: '10px',
    borderRadius: '5px',
    width: '25vw',
};

export interface DocValues {
    docId: string;
    type: string;
}

interface DocumentTabProps {
    onDocumentIdChange: (docValues: DocValues) => void;
    setRemarkTab: () => void;
    registryId: string;
    userRole: string;
}

const DocumentTab = (props: DocumentTabProps) => {
    const registryId = props.registryId;
    const [addLinkData, setAddLinkData] = useState({
        linkTitle: '',
        linkUrl: '',
    });
    const [editLinkData, setEditLinkData] = useState({
        linkTitle: '',
        linkUrl: '',
    });
    const [editFileData, setEditFileData] = useState({
        displayName: '',
    });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [linkData, setLinkData] = useState<any[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [fileData, setFileData] = useState<any[]>([]);
    const [errors, setErrors] = useState({
        linkTitle: '',
        linkUrl: '',
        displayName: '',
    });
    const [columnVisibilityModelForLinks, setColumnVisibilityModelForLinks] =
        useState<GridColumnVisibilityModel>({
            editArchive: false,
            download: false,
        });
    const [columnVisibilityModelForFiles, setColumnVisibilityModelForFiles] =
        useState<GridColumnVisibilityModel>({
            editArchive: false,
            download: true,
        });
    const [modalOpen, setModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [fetchNewData, setFetchNewData] = useState(false);
    const [success, setSuccess] = useState(false);
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [fileNameValue, setFileNameValue] = useState<string | null>(null);
    const [fileModal, setFileModal] = useState(false);
    const [isDocViewerLoading, setIsDocViewerLoading] = useState(true);
    const [documentId, setDocumentId] = useState('');
    const [documentType, setDocumentType] = useState('');

    const [editDocumentId, setEditDocumentId] = useState('');
    const [editLinkModalOpen, setEditLinkModalOpen] = useState(false);
    const [editFileModalOpen, setEditFileModalOpen] = useState(false);

    const handleEditLinkModalOpen = () => {
        setEditLinkModalOpen(!editLinkModalOpen);
    };

    const handleEditFileModalOpen = () => {
        setEditFileModalOpen(!editFileModalOpen);
    };

    useEffect(() => {
        if (props.userRole === 'ADMIN') {
            setColumnVisibilityModelForLinks({
                editArchive: true,
                download: false,
            });
            setColumnVisibilityModelForFiles({
                editArchive: true,
                download: true,
            });
        }
        const currentValue: DocValues = {
            docId: documentId,
            type: documentType,
        };
        props.onDocumentIdChange(currentValue);
    }, [documentId, documentType]);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    const [fileUploadOpen, setFileUploadOpen] = useState(false);

    const fetchData = async () => {
        const response = await getAllRegistryDocuments({
            registryId: registryId,
        });
        if (response) {
            const LinkData = [];
            const FileData = [];
            for (let i = 0; i < response.length; i++) {
                if (response[i].type === 'FILE') {
                    FileData.push(response[i]);
                } else {
                    LinkData.push(response[i]);
                }
            }
            setFileData(FileData);
            setLinkData(LinkData);
            setIsLoading(false);
        } else {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [fetchNewData]);

    const handleFileUploadToggle = (isOpen: boolean) => {
        setFileUploadOpen(isOpen);
    };

    const handleUploadComplete = () => {
        fetchData();
    };

    const handleFileNameChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldName: string
    ) => {
        const { value } = event.target;

        // Find the position of the last '.' in the current file name
        const lastDotIndex = editFileData.displayName.lastIndexOf('.');

        // Extract the extension and base name
        const originalExtension =
            lastDotIndex !== -1
                ? editFileData.displayName.substring(lastDotIndex) // Includes the dot (e.g., ".csv")
                : '';
        const baseName = lastDotIndex !== -1 ? value : editFileData.displayName;

        // Combine updated base name with the original extension
        const updatedDisplayName = `${baseName}${originalExtension}`;

        // Update state
        setEditFileData((prevState) => ({
            ...prevState,
            [fieldName]: updatedDisplayName,
        }));

        // Validate character count (min 5, max 250)
        const charCount = baseName.length;

        if (charCount < 5) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: 'File name must be at least 5 characters long.',
            }));
        } else if (charCount > 500) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: 'File name must be 500 characters or fewer.',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: '',
            }));
        }
    };

    const handleLinkDataChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldName: string
    ) => {
        let charLimit;
        const { value } = event.target;

        setEditLinkData((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }));

        const charCount = value.length;
        if ([fieldName].toString() === 'linkTitle') {
            charLimit = 100;
        } else {
            charLimit = 250;
        }

        let isValid = false;
        if (fieldName === 'linkTitle') {
            isValid = validateTitleFormat(value);
        } else if (fieldName === 'linkUrl') {
            isValid = validateDomainURLFormat(value);
        }

        if ((value && !isValid) || charCount > charLimit) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: 'Invalid URL Input',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: '',
            }));
        }
    };

    const handleValueChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        fieldName: string
    ) => {
        let charLimit;
        const { value } = event.target;

        setAddLinkData((prevState) => ({
            ...prevState,
            [fieldName]: value,
        }));

        const charCount = value.length;
        if ([fieldName].toString() === 'linkTitle') {
            charLimit = 100;
        } else {
            charLimit = 250;
        }

        let isValid = false;
        if (fieldName === 'linkTitle') {
            isValid = validateTitleFormat(value);
        } else if (fieldName === 'linkUrl') {
            isValid = validateDomainURLFormat(value);
        }

        if ((value && !isValid) || charCount > charLimit) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: 'Invalid URL Input',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [fieldName]: '',
            }));
        }
    };

    // const handleRemoveButtonClick = (
    //     e: React.MouseEvent<HTMLButtonElement>
    // ) => {
    //     e.stopPropagation();
    //     setMessage('Are you sure you want to remove the link to the document?');
    //     handleConfirmModalOpen();
    // };

    const handleEditButtonClick = async (
        e: React.MouseEvent<HTMLButtonElement>,
        id: string,
        type: string
    ) => {
        e.stopPropagation();
        if (type === 'LINK') {
            const response = await editDocumentLink({
                id: id,
                linkTitle: editLinkData.linkTitle,
                linkUrl: editLinkData.linkUrl,
            });
            if (response) {
                setMessage('Link details updated successfully.');
                setSuccess(true);
                fetchData();
            } else {
                setMessage('Failed to updated link details. Please try again.');
                setSuccess(false);
            }
            handleClick();
            handleEditLinkModalOpen();
        } else if (type === 'FILE') {
            const response = await editFileUpload({
                id: id,
                displayName: editFileData.displayName,
            });
            if (response && response.success) {
                setMessage(response.message);
                setSuccess(response.success);
                fetchData();
            } else {
                setMessage('Failed to updated file name. Please try again.');
                setSuccess(false);
            }
            handleClick();
            handleEditFileModalOpen();
        }
    };

    const handleEditCompOpen = (
        id: string,
        type: string,
        title: string,
        url: string
    ) => {
        if (type === 'LINK') {
            setEditLinkData({
                linkTitle: title,
                linkUrl: url,
            });
            setEditDocumentId(id);
            handleEditLinkModalOpen();
        } else if (type === 'FILE') {
            setEditFileData({
                displayName: title,
            });
            setEditDocumentId(id);
            handleEditFileModalOpen();
        }
    };

    const handleAddButtonClick = async () => {
        const requestBody = {
            registryId: registryId,
            linkTitle: addLinkData.linkTitle,
            linkUrl: addLinkData.linkUrl,
        };
        const response = await addDocumentLink(requestBody);
        if (response) {
            setMessage('Link added Successfully');
            handleClick();
            setSuccess(true);
            setAddLinkData({
                linkTitle: '',
                linkUrl: '',
            });
        } else {
            setMessage('Failed to add link');
            handleClick();
            setSuccess(false);
        }
        handleModalClose();
        setFetchNewData(!fetchNewData);
    };

    const disableAddLink = () => {
        return (
            addLinkData.linkTitle &&
            addLinkData.linkUrl &&
            !errors.linkTitle &&
            !errors.linkUrl
        );
    };

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>
                <Box sx={{ mt: 2 }}>
                    <Typography variant="body1">
                        {'No Documents Found'}
                    </Typography>
                </Box>
            </StyledGridOverlay>
        );
    }

    const handlePreviewOpen = async (id: string, title: string) => {
        setFileModal(true);
        setFileNameValue(title);

        const response = await getFileForPreview({ fileId: id });
        if (response) {
            const fileResource = new Blob([response.data], {
                type: 'application/pdf',
            });
            const fileUrl = URL.createObjectURL(fileResource);

            setFileUrl(fileUrl);
            setIsDocViewerLoading(false);
        } else {
            setFileModal(false);
            setMessage('Error occurred while fetching file');
            handleClick();
            setSuccess(false);
        }
    };

    const handleFileDownload = async (fileId: string, fileName: string) => {
        setMessage('File download started.');
        handleClick();
        setSuccess(true);
        const response = await downloadFileFromGCP(fileId);
        if (response) {
            setMessage('File download successful.');
            handleClick();
            setSuccess(true);

            const blob = new Blob([response.data]);
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            link.remove();

            window.URL.revokeObjectURL(downloadUrl);
        } else {
            setMessage('Error downloading file');
            handleClick();
            setSuccess(false);
        }
    };

    const handleFileModalClose = () => {
        setFileUrl(null);
        setFileModal(false);
    };

    return (
        <Stack sx={{ padding: '10px' }}>
            <Stack
                sx={{
                    border: '1px solid grey',
                    borderRadius: '3px',
                    padding: '5px 10px',
                }}
            >
                <Stack
                    sx={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '5px',
                    }}
                >
                    <Typography variant="subtitle1">
                        {'Document Links'}
                    </Typography>
                    {props.userRole === 'ADMIN' ? (
                        <Stack direction={'row'} spacing={'10px'}>
                            <StyledButton
                                variant="outlined"
                                startIcon={<AddLinkIcon />}
                                onClick={handleModalOpen}
                            >
                                <Typography variant="caption1">
                                    {'Add Link'}
                                </Typography>
                            </StyledButton>
                            <StyledButton
                                variant="outlined"
                                onClick={() => handleFileUploadToggle(true)}
                                startIcon={<CloudUploadIcon />}
                            >
                                <Typography variant="caption1">
                                    {'Upload File'}
                                </Typography>
                            </StyledButton>
                        </Stack>
                    ) : (
                        <></>
                    )}
                    <FileUploadModal
                        open={fileUploadOpen}
                        onToggle={handleFileUploadToggle}
                        id={registryId}
                        onUploadComplete={handleUploadComplete}
                        uploadFrom={'Registry'}
                    />
                    <GenericModal open={modalOpen} style={GenericModalStyle}>
                        <Stack spacing={'10px'}>
                            <Stack
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="subtitle1">
                                    {'Add Link'}
                                </Typography>
                                <IconButton onClick={handleModalClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Stack>
                            <TypoInput
                                labelvalue="Link Title"
                                placeholder={'Link Title'}
                                value={addLinkData.linkTitle}
                                onChange={(e) =>
                                    handleValueChange(e, 'linkTitle')
                                }
                                manditoryfield="true"
                            />
                            {errors.linkTitle && (
                                <Typography
                                    variant="caption2"
                                    color={'red'}
                                    sx={ErrorStyle}
                                >
                                    {errors.linkTitle}
                                </Typography>
                            )}
                            <TypoInput
                                labelvalue="Link URL"
                                placeholder={'Link URL'}
                                value={addLinkData.linkUrl}
                                onChange={(e) =>
                                    handleValueChange(e, 'linkUrl')
                                }
                                manditoryfield="true"
                            />
                            {errors.linkUrl && (
                                <Typography
                                    variant="caption2"
                                    color={'red'}
                                    sx={ErrorStyle}
                                >
                                    {errors.linkUrl}
                                </Typography>
                            )}
                            <Button
                                variant="outlined"
                                onClick={handleAddButtonClick}
                                disabled={!disableAddLink()}
                            >
                                <Typography variant="caption1">
                                    {'Add Link'}
                                </Typography>
                            </Button>
                        </Stack>
                    </GenericModal>
                </Stack>
                <Stack
                    sx={{
                        padding: '5px',
                        borderRadius: '3px',
                    }}
                >
                    <ClientSideDataTable
                        columns={useDocumentInfoColumns(
                            setDocumentId,
                            setDocumentType,
                            props,
                            handleEditCompOpen,
                            handlePreviewOpen,
                            handleFileDownload
                        )}
                        rowsData={linkData}
                        isLoading={isLoading}
                        checkboxSelection={false}
                        columnVisibilityModel={columnVisibilityModelForLinks}
                        onColumnVisibilityModelChange={
                            setColumnVisibilityModelForLinks
                        }
                        hideFooter={false}
                        slotProps={{
                            loadingOverlay: {
                                variant: 'skeleton',
                                noRowsVariant: 'skeleton',
                            },
                        }}
                        slots={{
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        resizeThrottleMs={190}
                        pageSizeOptions={[5, 10, 25, 50, 75, 100]}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 5, page: 0 },
                            },
                        }}
                    />
                </Stack>
                <Stack
                    sx={{
                        padding: '5px',
                        borderRadius: '3px',
                        marginTop: '20px',
                    }}
                    spacing={'10px'}
                >
                    <Stack
                        sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="subtitle1">
                            {'Uploaded Document'}
                        </Typography>
                    </Stack>
                    <ClientSideDataTable
                        columns={useDocumentInfoColumns(
                            setDocumentId,
                            setDocumentType,
                            props,
                            handleEditCompOpen,
                            handlePreviewOpen,
                            handleFileDownload
                        )}
                        rowsData={fileData}
                        isLoading={isLoading}
                        checkboxSelection={false}
                        hideFooter={false}
                        slotProps={{
                            loadingOverlay: {
                                variant: 'skeleton',
                                noRowsVariant: 'skeleton',
                            },
                        }}
                        columnVisibilityModel={columnVisibilityModelForFiles}
                        onColumnVisibilityModelChange={
                            setColumnVisibilityModelForFiles
                        }
                        slots={{
                            noRowsOverlay: CustomNoRowsOverlay,
                        }}
                        resizeThrottleMs={190}
                        pageSizeOptions={[5, 10, 25, 50, 75, 100]}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 5, page: 0 },
                            },
                        }}
                    />
                    <Dialog
                        open={fileModal}
                        onClose={handleFileModalClose}
                        maxWidth="lg"
                        fullWidth
                    >
                        <DialogContent>
                            {fileUrl ? (
                                <DocumentViewer
                                    filePreview={{
                                        fileUrl,
                                        fileName: fileNameValue,
                                    }}
                                    loading={isDocViewerLoading}
                                />
                            ) : null}
                        </DialogContent>
                    </Dialog>
                </Stack>
            </Stack>
            <CustomSnackbar
                open={openSnackBar}
                message={message}
                success={success}
                onClose={handleClose}
                autoHideDuration={1800}
            />
            <GenericModal open={editLinkModalOpen} style={GenericModalStyle}>
                <Stack spacing={'10px'}>
                    <Stack
                        sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="subtitle1">
                            {'Edit Link'}
                        </Typography>
                        <IconButton onClick={handleEditLinkModalOpen}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <TypoInput
                        labelvalue="Link Title"
                        placeholder={'Link Title'}
                        value={editLinkData.linkTitle}
                        onChange={(e) => handleLinkDataChange(e, 'linkTitle')}
                        manditoryfield="true"
                    />
                    {errors.linkTitle && (
                        <Typography
                            variant="caption2"
                            color={'red'}
                            sx={ErrorStyle}
                        >
                            {errors.linkTitle}
                        </Typography>
                    )}
                    <TypoInput
                        labelvalue="Link URL"
                        placeholder={'Link URL'}
                        value={editLinkData.linkUrl}
                        onChange={(e) => handleLinkDataChange(e, 'linkUrl')}
                        manditoryfield="true"
                    />
                    {errors.linkUrl && (
                        <Typography
                            variant="caption2"
                            color={'red'}
                            sx={ErrorStyle}
                        >
                            {errors.linkUrl}
                        </Typography>
                    )}
                    <Button
                        variant="outlined"
                        onClick={(e) =>
                            handleEditButtonClick(e, editDocumentId, 'LINK')
                        }
                    >
                        <Typography variant="caption1">{'Save'}</Typography>
                    </Button>
                </Stack>
            </GenericModal>
            <GenericModal open={editFileModalOpen} style={GenericModalStyle}>
                <Stack spacing={'10px'}>
                    <Stack
                        sx={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="subtitle1">
                            {'Edit File Name'}
                        </Typography>
                        <IconButton onClick={handleEditFileModalOpen}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                    <Stack
                        direction={'row'}
                        sx={{
                            alignItems: 'flex-end',
                            flexDirection: 'row',
                        }}
                        spacing={'5px'}
                    >
                        <TypoInput
                            labelvalue="File Name"
                            placeholder={'File Name'}
                            value={editFileData.displayName.substring(
                                0,
                                editFileData.displayName.lastIndexOf('.')
                            )}
                            onChange={(e) =>
                                handleFileNameChange(e, 'displayName')
                            }
                            manditoryfield="true"
                        />
                        <Typography variant="body1">
                            {'.'}
                            {editFileData.displayName.substring(
                                editFileData.displayName.lastIndexOf('.') + 1
                            )}
                        </Typography>
                    </Stack>
                    {errors.displayName && (
                        <Typography
                            variant="caption2"
                            color={'red'}
                            sx={ErrorStyle}
                        >
                            {errors.displayName}
                        </Typography>
                    )}
                    <Button
                        variant="outlined"
                        onClick={(e) =>
                            handleEditButtonClick(e, editDocumentId, 'FILE')
                        }
                        disabled={Object.values(errors).some(
                            (error) => error !== ''
                        )}
                    >
                        <Typography variant="caption1">{'Save'}</Typography>
                    </Button>
                </Stack>
            </GenericModal>
        </Stack>
    );
};

export default DocumentTab;
