import { Stack, MenuItem } from '@mui/material';
import { useState, ChangeEvent } from 'react';
import TypoInput from '../../molecules/TypoInput';
import {
    validateMeeshoEmailFormat,
    validateTitleFormat,
} from '../../../utils/validations';
import Typography from '../../atoms/Typography';
import Button from '../../atoms/Button';

const ErrorStyle = {
    marginLeft: '14px',
};

export interface UserFormProps {
    handleContinue: (name: string, email: string, role: string) => void;
}
const RoleValues = [
    {
        id: 1,
        option: 'USER',
        value: 'USER',
    },
    {
        id: 2,
        option: 'ADMIN',
        value: 'ADMIN',
    },
];

export const UserForm = (props: UserFormProps) => {
    const [userDetails, setUserDetails] = useState({
        name: '',
        email: '',
        role: '',
    });
    const [errors, setErrors] = useState({
        name: '',
        email: '',
        role: '',
    });

    const handleNameChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value } = event.target;

        setUserDetails((prevState) => ({
            ...prevState,
            name: value,
        }));

        const charCount = value.length;

        if ((value && !validateTitleFormat(value)) || charCount > 30) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: 'Invalid Name Input',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                name: '',
            }));
        }
    };

    const handleEmailChange = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const value = event.target.value.replace(/\s/g, '');

        setUserDetails((prevState) => ({
            ...prevState,
            email: value,
        }));

        const charCount = value.length;

        if ((value && !validateMeeshoEmailFormat(value)) || charCount > 64) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: 'Invalid Email Input',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                email: '',
            }));
        }
    };

    const handleRoleChange = (event: ChangeEvent<{ value: unknown }>) => {
        const { value } = event.target as HTMLSelectElement;

        setUserDetails((prevState) => ({
            ...prevState,
            role: value as string,
        }));

        if (!value) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                role: 'Role is required',
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                role: '',
            }));
        }
    };

    const areMandatoryFieldsFilled = () => {
        return (
            !errors.name &&
            !errors.email &&
            !errors.role &&
            userDetails.name &&
            userDetails.email &&
            userDetails.role
        );
    };

    return (
        <Stack spacing={'1.5vh'} sx={{ width: '35vw' }}>
            <div>
                <TypoInput
                    labelvalue="Name"
                    value={userDetails.name}
                    onChange={(e) => handleNameChange(e)}
                    manditoryfield="true"
                ></TypoInput>
                {errors.name && (
                    <Typography
                        variant="caption2"
                        color={'red'}
                        sx={ErrorStyle}
                    >
                        {errors.name}
                    </Typography>
                )}
            </div>
            <div>
                <TypoInput
                    labelvalue="Email"
                    value={userDetails.email}
                    onChange={(e) => handleEmailChange(e)}
                    manditoryfield="true"
                ></TypoInput>
                {errors.email && (
                    <Typography
                        variant="caption2"
                        color={'red'}
                        sx={ErrorStyle}
                    >
                        {errors.email}
                    </Typography>
                )}
            </div>
            <div>
                <TypoInput
                    select
                    labelvalue="Role"
                    value={userDetails.role}
                    onChange={handleRoleChange}
                    fullWidth
                >
                    {RoleValues.map((data) => (
                        <MenuItem key={data.id} value={data.value}>
                            <Typography
                                variant="body1"
                                sx={{ textTransform: 'capitalize' }}
                            >
                                {data.option}
                            </Typography>
                        </MenuItem>
                    ))}
                </TypoInput>
            </div>
            <Stack
                sx={{
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingTop: '3vh',
                }}
            >
                <Button
                    type="submit"
                    variant="contained"
                    onClick={() => {
                        props.handleContinue(
                            userDetails.name,
                            userDetails.email,
                            userDetails.role
                        );
                        setUserDetails({
                            name: '',
                            email: '',
                            role: '',
                        });
                    }}
                    sx={{ width: '15vw' }}
                    disabled={!areMandatoryFieldsFilled()}
                >
                    <Typography variant="body1">{'Add User'}</Typography>
                </Button>
            </Stack>
        </Stack>
    );
};
