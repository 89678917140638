import React, { useState, useEffect } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement,
} from 'chart.js';
import {
    getAllRegistryDashboard,
    getAllRiskDashboard,
} from '../../services/api';
import { CircularProgress, Divider, Stack } from '@mui/material';
import MainTemplate from '../../components/templates/MainTemplate';
import './Dashboard.css';
import Typography from '../../components/atoms/Typography';
import useSnackBar from '../../utils/hooks';
import CustomSnackbar from '../../components/molecules/CustomSnackbar';
import FormSubmissionStats from './RiskAssessmentDashboard';

ChartJS.register(
    ArcElement,
    Tooltip,
    Legend,
    CategoryScale,
    LinearScale,
    BarElement
);

interface DashboardData {
    totalRisks: number;
    risksByCategory: Record<string, number>;
    risksByStatus: Record<string, number>;
}

interface DashboardSummary {
    totalPolicies: number;
    totalProcedures: number;
    totalMandatoryDocuments: number;
    pendingApprovals: number;
    inProgressApprovals: number;
    completedApprovals: number;
    approvalsByLevel: Record<string, number>;
}

const DashboardPage: React.FC = () => {
    const [dashboardData, setDashboardData] = useState<DashboardData>({
        totalRisks: 0,
        risksByCategory: { '': 0 },
        risksByStatus: { '': 0 },
    });
    const [summary, setSummary] = useState<DashboardSummary>({
        totalPolicies: 0,
        totalProcedures: 0,
        totalMandatoryDocuments: 0,
        pendingApprovals: 0,
        inProgressApprovals: 0,
        completedApprovals: 0,
        approvalsByLevel: { '': 0 },
    });
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();

    useEffect(() => {
        const fetchSummary = async () => {
            const response = await getAllRegistryDashboard();
            if (response) {
                setSummary(response);
            } else {
                setMessage('Error fetching dashboard summary.');
                handleClick();
                setSuccess(false);
            }
            setLoading(false);
        };
        const fetchDashboardData = async () => {
            const response = await getAllRiskDashboard();
            if (response) {
                setDashboardData(response);
            } else {
                setMessage('Error fetching dashboard summary.');
                handleClick();
                setSuccess(false);
            }
        };

        fetchDashboardData();
        fetchSummary();
    }, []);

    const risksByCategoryData = {
        labels: Object.keys(dashboardData.risksByCategory),
        datasets: [
            {
                data: Object.values(dashboardData.risksByCategory),
                backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#4BC0C0',
                    '#9966FF',
                ],
            },
        ],
    };

    const risksByStatusData = {
        labels: Object.keys(dashboardData.risksByStatus),
        datasets: [
            {
                data: Object.values(dashboardData.risksByStatus),
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#9966FF'],
            },
        ],
    };

    //Registry

    const documentTypeData = {
        labels: ['Policies', 'Procedures', 'Mandatory Documents'],
        datasets: [
            {
                data: [
                    summary.totalPolicies,
                    summary.totalProcedures,
                    summary.totalMandatoryDocuments,
                ],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
        ],
    };

    const approvalStatusData = {
        labels: ['Pending', 'In Progress', 'Completed'],
        datasets: [
            {
                data: [
                    summary.pendingApprovals,
                    summary.inProgressApprovals,
                    summary.completedApprovals,
                ],
                backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
            },
        ],
    };

    const approvalLevelOrder = ['L1', 'L2', 'L3', 'L4'];

    const approvalLevelData = {
        labels: approvalLevelOrder,
        datasets: [
            {
                label: 'Approvals by Level',
                data: approvalLevelOrder.map(
                    (level) => summary.approvalsByLevel[level] || 0
                ),
                backgroundColor: '#36A2EB',
            },
        ],
    };

    return (
        <MainTemplate
            main={
                <>
                    {loading && (!dashboardData || !summary) ? (
                        <Stack
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                height: '100vh',
                                width: '100%',
                            }}
                        >
                            <CircularProgress />
                        </Stack>
                    ) : (
                        <Stack
                            sx={{
                                width: '100%',
                                height: '100%',
                                backgroundColor: '#D6D6D6',
                                overflowX: 'hidden',
                            }}
                        >
                            <Stack
                                sx={{
                                    height: '100%',
                                    margin: '15px',
                                    borderRadius: '10px',
                                    padding: '10px',
                                    backgroundColor: 'white',
                                }}
                            >
                                <Stack sx={{ width: '100%' }}>
                                    <Typography
                                        variant="h2"
                                        style={{ textAlign: 'center' }}
                                    >
                                        Risk Management Dashboard
                                    </Typography>
                                    <Stack className="total-risks">
                                        <Typography
                                            variant="h3"
                                            style={{ textAlign: 'center' }}
                                        >
                                            Total Risks:{' '}
                                            {dashboardData.totalRisks}
                                        </Typography>
                                    </Stack>
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-evenly',
                                            marginBottom: '20px',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Stack
                                            style={{
                                                width: '40%',
                                                maxWidth: '500px',
                                            }}
                                        >
                                            <Typography
                                                variant="h3"
                                                style={{ textAlign: 'center' }}
                                            >
                                                Risks by Category
                                            </Typography>
                                            <Doughnut
                                                data={risksByCategoryData}
                                            />
                                        </Stack>
                                        <Stack
                                            style={{
                                                width: '40%',
                                                maxWidth: '500px',
                                            }}
                                        >
                                            <Typography
                                                variant="h3"
                                                style={{ textAlign: 'center' }}
                                            >
                                                Risks by Status
                                            </Typography>
                                            <Doughnut
                                                data={risksByStatusData}
                                            />
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Divider />
                                <Stack
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    <Typography
                                        variant="h2"
                                        style={{ textAlign: 'center' }}
                                    >
                                        Approval Dashboard
                                    </Typography>
                                    <Stack
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-evenly',
                                            marginBottom: '20px',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <Stack
                                            style={{
                                                width: '45%',
                                                maxWidth: '500px',
                                            }}
                                        >
                                            <Typography
                                                variant="h3"
                                                style={{ textAlign: 'center' }}
                                            >
                                                Document Types
                                            </Typography>
                                            <Doughnut data={documentTypeData} />
                                        </Stack>
                                        <Stack
                                            style={{
                                                width: '45%',
                                                maxWidth: '500px',
                                            }}
                                        >
                                            <Typography
                                                variant="h3"
                                                style={{ textAlign: 'center' }}
                                            >
                                                Approval Status
                                            </Typography>
                                            <Doughnut
                                                data={approvalStatusData}
                                            />
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Stack
                                            style={{
                                                width: '45%',
                                                maxWidth: '500px',
                                            }}
                                        >
                                            <Typography
                                                variant="h3"
                                                style={{ textAlign: 'center' }}
                                            >
                                                Approvals by Level
                                            </Typography>
                                            <Bar data={approvalLevelData} />
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Divider />
                                <Stack
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                    }}
                                >
                                    <Typography
                                        variant="h2"
                                        style={{ textAlign: 'center' }}
                                    >
                                        Risk Assessment Dashboard
                                    </Typography>
                                    <FormSubmissionStats />
                                </Stack>
                            </Stack>
                        </Stack>
                    )}
                    <CustomSnackbar
                        open={openSnackBar}
                        message={message}
                        success={success}
                        onClose={handleClose}
                        autoHideDuration={2500}
                    />
                </>
            }
        />
    );
};

export default DashboardPage;
