import { IconButton, Stack, styled } from '@mui/material';
import MainTemplate from '../../components/templates/MainTemplate';
import TableHeader from '../../components/organisms/TableHeader';
import DataTable from '../../components/organisms/DataTable';
import { getPolicyInfoColumns } from '../../utils/helper';
import { useState, useEffect, useCallback } from 'react';
import { createRegistry, searchEntries } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import Button from '../../components/atoms/Button';
import GenericModal from '../../components/organisms/GenericModal';
import Typography from '../../components/atoms/Typography';
import TypoInput from '../../components/molecules/TypoInput';
import theme from '../../theme';
import { validateTitleFormat } from '../../utils/validations';
import useSnackBar from '../../utils/hooks';
import { FilterFieldValues } from '../../utils/constants';
import { debounce } from 'lodash';
import CustomSnackbar from '../../components/molecules/CustomSnackbar';

const StyledButton = styled(Button)({
    padding: '5px 8px',
    width: '100px',
});

const ProcedurePage = () => {
    const navigate = useNavigate();
    const [reportList, setReportList] = useState([]);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);
    const [registryName, setRegistryName] = useState('');
    const [errors, setErrors] = useState({ registryName: '' });
    const [success, setSuccess] = useState(false);
    const [message, setMessage] = useState('');
    const { openSnackBar, handleClick, handleClose } = useSnackBar();
    const [fetchNewData, setFetchNewData] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [statusList, setStatusList] = useState<string[]>([]);

    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => {
        setModalOpen(false);
        setRegistryName('');
        setErrors({ registryName: '' });
    };

    const fetchData = async (query: string, statusFilter: string[]) => {
        setIsLoading(true);
        const response = await searchEntries(
            query,
            'registry',
            statusFilter,
            'PROCEDURE',
            paginationModel.page,
            paginationModel.pageSize,
            'createdAt'
        );
        if (response && response.success == null) {
            setReportList(response.content);
            setRowCount(response.totalElements);
        } else {
            setMessage('An error occurred');
            handleClick();
            setSuccess(false);
        }
        setIsLoading(false);
    };

    const debouncedFetchData = useCallback(
        debounce((query: string, statusFilter: string[]) => {
            fetchData(query, statusFilter);
        }, 1000),
        [paginationModel.page, paginationModel.pageSize]
    );

    useEffect(() => {
        debouncedFetchData(searchQuery, statusList);
    }, [searchQuery, statusList, debouncedFetchData, fetchNewData]);

    const handleValueChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        const { value } = e.target;
        setRegistryName(value);

        const charCount = value.length;

        if ((value && !validateTitleFormat(value)) || charCount > 200) {
            setErrors({ registryName: 'Invalid Input' });
        } else {
            setErrors({ registryName: '' });
        }
    };

    const handleAddRegistry = async () => {
        if (registryName.trim().length < 2) {
            setErrors({
                registryName: 'Name must have atleast least 2 characters',
            });
            return;
        }

        const response = await createRegistry({
            name: registryName,
            type: 'PROCEDURE',
        });
        if (response) {
            setMessage('Procedure created successfully');
            handleClick();
            setSuccess(true);
        } else {
            setMessage('Failed to create Procedure. Please try again.');
            handleClick();
            setSuccess(false);
        }
        setFetchNewData(!fetchNewData);
        handleModalClose();
    };

    const ProfileTableHeaderOption = [
        {
            id: 1,
            label: 'Add Procedure',
            icon: <ControlPointIcon />,
            onClick: () => handleModalOpen(),
        },
    ];

    const handleFilterChange = (list: string[]) => {
        setStatusList(list);
    };

    return (
        <MainTemplate
            main={
                <Stack
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <TableHeader
                        headerName={'List of Procedure'}
                        searchQuery={searchQuery}
                        setSearchQuery={setSearchQuery}
                        handleFilterChange={handleFilterChange}
                        filterList={FilterFieldValues}
                        menuItems={ProfileTableHeaderOption}
                        searchVisible={true}
                        searchFieldPlaceholder={'Search by Procedure Name'}
                    />
                    <DataTable
                        columns={getPolicyInfoColumns((row) => {
                            const { id } = row;
                            navigate(
                                `/u/registrydetails?id=${id}&type=procedures`
                            );
                        })}
                        rowsData={reportList || []}
                        rowCount={rowCount}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        isLoading={isLoading}
                        checkboxSelection={false}
                        sx={{
                            flexGrow: 1,
                        }}
                        resizeThrottleMs={190}
                    />
                    <GenericModal
                        open={modalOpen}
                        style={{
                            backgroundColor:
                                theme.palette.structuralColors.white,
                            padding: '10px',
                            borderRadius: '5px',
                            width: '25vw',
                        }}
                    >
                        <Stack spacing={'10px'}>
                            <Stack
                                sx={{
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                }}
                            >
                                <Typography variant="subtitle1">
                                    {'Create a new Procedure'}
                                </Typography>
                                <IconButton onClick={handleModalClose}>
                                    <CloseIcon />
                                </IconButton>
                            </Stack>
                            <TypoInput
                                labelvalue="Procedure Name"
                                placeholder={'Procedure Name'}
                                value={registryName}
                                onChange={(e) => handleValueChange(e)}
                                manditoryfield="true"
                            />
                            {errors.registryName && (
                                <Typography variant="caption2" color={'red'}>
                                    {errors.registryName}
                                </Typography>
                            )}
                            <Stack
                                direction={'row'}
                                spacing={'10px'}
                                sx={{ paddingTop: '20px' }}
                            >
                                <StyledButton
                                    variant="outlined"
                                    onClick={handleModalClose}
                                >
                                    <Typography variant="caption1">
                                        {'Cancel'}
                                    </Typography>
                                </StyledButton>
                                <StyledButton
                                    variant="outlined"
                                    onClick={handleAddRegistry}
                                    disabled={
                                        errors.registryName !== '' ||
                                        registryName.trim().length < 2
                                    }
                                >
                                    <Typography variant="caption1">
                                        {'Save'}
                                    </Typography>
                                </StyledButton>
                            </Stack>
                        </Stack>
                    </GenericModal>
                    <CustomSnackbar
                        open={openSnackBar}
                        message={message}
                        success={success}
                        onClose={handleClose}
                        autoHideDuration={2500}
                    />
                </Stack>
            }
        />
    );
};

export default ProcedurePage;
